<template>
    <public-invoicable-view :loading="loading" :invoicable="invoicable" :error-message="errorMessage" />
</template>

<script>
import { getCreditnoteByUUID } from '@/services/creditnote';
import PublicInvoicableView from './PublicInvoicableView';

export default {
    components: {
        PublicInvoicableView
    },

    data() {
        return {
            invoicable: null,
            loading: true,
            errorMessage: null
        };
    },

    async created() {
        this.loading = true;
        try {
            this.invoicable = await getCreditnoteByUUID(this.$route.params.uuid);
        } catch (e) {
            this.errorMessage = this.$t('errors.cannotFindDocument');
            this.loading = false;
            return window.location.replace('https://dexxter.be');
        } finally {
            this.loading = false;
        }
    }
};
</script>
